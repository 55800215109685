import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadSideCoughSlash } from '@fortawesome/pro-solid-svg-icons/faHeadSideCoughSlash';
import { faWifi } from '@fortawesome/pro-solid-svg-icons/faWifi';
import { faVolumeLow } from '@fortawesome/pro-solid-svg-icons/faVolumeLow';
import { faScrewdriverWrench } from '@fortawesome/pro-solid-svg-icons/faScrewdriverWrench';

import MaxWidth from 'layouts/max-width';
import Spacing from 'layouts/Spacing';

const Wrap = styled.div`
	background-color: ${p => p.theme.colors.blue100};
	padding: 45px 0;
	${p =>
		p.theme.media.smallOnly(css`
			padding: 30px 0;
			margin: 30px 0;
		`)}
`;

const EnergyRating = styled.div`
	color: ${p => p.theme.colors.white};
	padding: 0;
	${p =>
		p.align !== 'center' &&
		css`
			padding: 0 0 10px 15px;
			float: right;
		`};
	${p =>
		p.align === 'center' &&
		css`
			svg {
				height: 65px;
				margin: 0 auto;
				display: block;
				${p =>
					p.theme.media.mediumDown(css`
						height: 50px;
					`)}
			}
		`};

	svg {
		width: 70px;

		text {
			fill: ${p => p.theme.colors.white};
			font-weight: bold;
		}
	}
	span {
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 1px;
		color: ${p => p.theme.colors.black};
		${p =>
			p.theme.media.XSmallOnly(css`
				font-size: 13px;
			`)}
	}
`;

const FeaturesList = styled.div`
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: space-between;

		li {
			margin: 0 10px 0;
			${p =>
				!p.countIsEven
					? css`
							&:nth-last-child(-n + 2) {
								margin-bottom: 0;
							}
					  `
					: css`
							&:last-of-type {
								margin-bottom: 0;
							}
					  `}
			${p =>
				p.theme.media.mediumOnly(css`
					margin: 0px 10px 40px;
					width: calc(33.3334% - 20px);
				`)}
			${p =>
				p.theme.media.smallOnly(css`
					margin: 0px 10px 40px;
					width: calc(50% - 20px);
				`)}
			span {
				display: block;
				margin-top: 20px;
				text-transform: uppercase;
				font-size: 16px;
				line-height: 20px;
				font-weight: 500;
				letter-spacing: 1px;
				text-align: center;
				${p =>
					p.theme.media.smallOnly(css`
						font-size: 15px;
					`)}
				${p =>
					p.theme.media.XSmallOnly(css`
						font-size: 13px;
					`)}
			}
		}
	}
`;

const Icon = styled.div`
	width: 65px;
	height: 65px;
	border-radius: 100%;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	background: ${p => p.theme.colors.blue200};
	${p =>
		p.theme.media.smallOnly(css`
			width: 50px;
			height: 50px;
		`)}
	svg {
		width: 25px;
		height: 25px;
		color: ${p => p.theme.colors.black};
		${p =>
			p.theme.media.smallOnly(css`
				width: 20px;
				height: 20px;
			`)}
	}
`;

export function EnergyClass({ type, align, showText = false }) {
	if (!type) return;

	let color = '#50af2f';
	switch (type) {
		case 'A+++':
			color = '#50af2f';
			break;
		case 'A++':
			color = '#4ab745';
			break;
		case 'A+':
			color = '#bfd535';
			break;
		case 'A':
			color = '#fcf004';
			break;
		case 'B':
			color = '#ffb610';
			break;
		case 'C':
			color = '#f56f21';
			break;
		case 'D':
			color = '#ef1c24';
			break;

		default:
			break;
	}

	return (
		<EnergyRating align={align}>
			<svg viewBox="0 0 60 30" aria-label={`Energiklasse ${type}`}>
				<polygon
					points="0 15,12 0,60 0,60 30,12 30"
					fill={color}></polygon>
				<text x="12" y="20">
					{type}
				</text>
			</svg>
			{showText && <span>Energiklasse</span>}
		</EnergyRating>
	);
}

export default function Features({ productFeatures = [], energyClass = '' }) {
	if (!energyClass && !productFeatures?.length > 0) return;

	return (
		<Spacing>
			<Wrap>
				<MaxWidth>
					<FeaturesList
						countIsEven={productFeatures?.length % 2 === 0}>
						<ul>
							{productFeatures.map((feature, i) => {
								return (
									<li key={i} aria-label={feature}>
										<Icon>
											<FontAwesomeIcon
												icon={
													feature === 'Allergifilter'
														? faHeadSideCoughSlash
														: feature ===
														  'Innebygd WIFI'
														? faWifi
														: feature ===
														  'Lavt lydnivå'
														? faVolumeLow
														: feature ===
														  'Montering inkludert'
														? faScrewdriverWrench
														: ''
												}
												size="xl"
											/>
										</Icon>
										<span>{feature}</span>
									</li>
								);
							})}
							{energyClass && (
								<li aria-label={`Energiklasse ${energyClass}`}>
									<EnergyClass
										type={energyClass}
										align="center"
										showText={true}
									/>{' '}
								</li>
							)}
						</ul>
					</FeaturesList>
				</MaxWidth>
			</Wrap>
		</Spacing>
	);
}
